// constants
import { GroupData, Permission, User } from "../../helpers/types/group";
import { GroupActionTypes } from "./constants";

export interface GroupActionType {
  type:
    | GroupActionTypes.API_RESPONSE_SUCCESS
    | GroupActionTypes.API_RESPONSE_ERROR
    | GroupActionTypes.GET_GROUP_LIST
    | GroupActionTypes.ADD_GROUP
    | GroupActionTypes.SET_CURRENT_GROUP
    | GroupActionTypes.UPDATE_GROUP_PERMISSIONS
    | GroupActionTypes.UPDATE_GROUP_USERS
    | GroupActionTypes.SET_LOADING_GROUP
    | GroupActionTypes.UPDATE_GROUP_NAME
    | GroupActionTypes.UPDATE_GROUP_OWNER
    | GroupActionTypes.DELETE_GROUP
  payload?: {} | string;
}

// common success
export const groupApiResponseSuccess = (
    actionType: string,
    groupList: GroupData[] | []
  ): GroupActionType => ({
    type: GroupActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, groupList },
});

// common error
export const groupApiResponseError = (
    actionType: string,
    error: string
  ): GroupActionType => ({
    type: GroupActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// Group list
export const getGroupList = (): GroupActionType => ({
    type: GroupActionTypes.GET_GROUP_LIST
  });

export const addGroup = (
  group: GroupData
): GroupActionType => ({
  type: GroupActionTypes.ADD_GROUP,
  payload: { group },
});

// Set Current group
export const setCurrentGroup = (
  currentGroup: GroupData | {}
): GroupActionType => ({
  type: GroupActionTypes.SET_CURRENT_GROUP,
  payload: { currentGroup },
});

// Set group permissions
export const setGroupPermissions = (
  permissions: Permission[] | []
): GroupActionType => ({
  type: GroupActionTypes.UPDATE_GROUP_PERMISSIONS,
  payload: { permissions }
});

export const setGroupUsers = (
  users: User[] | []
): GroupActionType => ({
  type: GroupActionTypes.UPDATE_GROUP_USERS,
  payload: { users }
});

export const setLoadingGroup = (
  isLoading: boolean
): GroupActionType => ({
  type: GroupActionTypes.SET_LOADING_GROUP,
  payload: { isLoading },
});

// Update group name
export const updateGroupName = (
  groupId: string,
  newName: string
): GroupActionType => ({
  type: GroupActionTypes.UPDATE_GROUP_NAME,
  payload: { groupId, newName },
});

// Update group owner
export const updateGroupOwner = (
  groupId: string,
  newOwnerId: string
): GroupActionType => ({
  type: GroupActionTypes.UPDATE_GROUP_OWNER,
  payload: { groupId, newOwnerId },
});

// Delete group
export const deleteGroup = (
  groupId: string
): GroupActionType => ({
  type: GroupActionTypes.DELETE_GROUP,
  payload: { groupId },
});
