import { APICore } from "./apiCore";

const api = new APICore();

// account
function login(params: { email: string; password: string }) {
  const baseUrl = "/login/";
  return api.create(`${baseUrl}`, params);
}

function logout() {
  const baseUrl = "/logout/";
  return api.create(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/register/";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
  const baseUrl = "/forget-password/";
  return api.create(`${baseUrl}`, params);
}

// Profile Pictures Loading
async function loadUserPicture(pictureName: string) {
  const baseUrl = "/static/public/userpictures/" + pictureName
  const res = await api.getFile(`${baseUrl}`, {});
  return URL.createObjectURL(res.data);
}

export { login, logout, signup, forgotPassword, loadUserPicture };
