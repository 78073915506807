import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import Chat from "./chat/reducers";
import Weebsocket from "./websocket/reducers";
import Group from "./groups/reducers"

export default combineReducers({
  Auth,
  Layout,
  Chat,
  Weebsocket,
  Group
});
