// Definir os tipos de ação para o chat
export enum ChatActionTypes {
    API_RESPONSE_SUCCESS = "@@chat/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@chat/API_RESPONSE_ERROR",
    GET_CHAT_LIST = "@@chat/GET_CHAT_LIST",
    ADD_MESSAGE_TO_CHAT = "@@chat/ADD_MESSAGE_TO_CHAT",
    SET_CURRENT_CHAT = "@@chat/SET_CURRENT_CHAT",
    SET_CHAT_CATEGORIES = "@@chat/SET_CHAT_CATEGORIES",
    SET_SELECTED_CATEGORY = "@@chat/SET_SELECTED_CATEGORY",
    SET_LOADING_CHAT = "@@chat/SET_LOADING_CHAT",
}