// Definir os tipos de ação para o grupo
export enum GroupActionTypes {
    API_RESPONSE_SUCCESS = "@@group/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@group/API_RESPONSE_ERROR",
    GET_GROUP_LIST = "@@group/GET_GROUP_LIST",
    ADD_GROUP = "@@group/ADD_GROUP",
    SET_CURRENT_GROUP = "@@group/SET_CURRENT_GROUP",
    UPDATE_GROUP_PERMISSIONS = "@@group/SET_GROUP_PERMISSIONS",
    UPDATE_GROUP_USERS = "@@group/SET_GROUP_USERS",
    SET_LOADING_GROUP = "@@group/SET_LOADING_GROUP",
    UPDATE_GROUP_NAME = "@@group/UPDATE_GROUP_NAME",
    UPDATE_GROUP_OWNER = "@@group/UPDATE_GROUP_OWNER",
    DELETE_GROUP = "@@group/DELETE_GROUP",
}
