import { all } from "redux-saga/effects";

import authSaga from "./auth/saga"
import layoutSaga from "./layout/saga";
import chatSaga from "./chat/saga";
import WsSaga from "./websocket/saga";
import groupSaga from "./groups/saga";

export default function* rootSaga() {
  yield all([authSaga(), layoutSaga(), chatSaga(), WsSaga(), groupSaga()]);
}
