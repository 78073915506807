import { APICore } from "./apiCore";

const api = new APICore();

// Group List Loading (GET)
function loadGroupList(params: { page: number }) {
  const baseUrl = "/groups/";
  return api.get(`${baseUrl}`, params);
}

// Group Loading by ID (GET)
function loadGroupById(params: { groupId: string }) {
  const baseUrl = `/groups/${params.groupId}`;
  return api.get(`${baseUrl}`, {});
}

// Create Group (POST)
function createGroup(params: { groupData: any }) {
  const baseUrl = "/groups/";
  return api.create(`${baseUrl}`, params.groupData);
}

// Update Group (PUT)
async function updateGroup(params: { groupId: string; groupData: any }) {
  const baseUrl = `/groups/${params.groupId}`;
  const res = await api.update(`${baseUrl}`, params.groupData);
  return res;
}

// Delete Group (DELETE)
function deleteGroup(params: { groupId: string }) {
  const baseUrl = `/groups/${params.groupId}`;
  return api.delete(`${baseUrl}`);
}

export { loadGroupList, loadGroupById, createGroup, updateGroup, deleteGroup };