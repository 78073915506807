// saga.ts
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { WsActionType, wsFormat } from "./actions";
import { RootState } from "../store";
import { WsActionTypes } from "./constants";
import { setLoadingChat } from "../actions";


// Seleciona o websocket do estado
const getWebsocket = (state: RootState) => state.Weebsocket.websocket;

// Seleciona o status do websocket do estado
const getWebsocketConnected = (state: RootState) => state.Weebsocket.websocketConnected;

/**
 * Login the user
 * @param {*} payload - username and password
 */

// Envia uma mensagem pelo websocket
function* sendWebsocketMessage(action: WsActionType): Generator<any, void, any> {
  try {
    // Pega o websocket do estado
    const websocket: any = yield select(getWebsocket);
    // Pega o status do websocket do estado
    const websocketConnected: boolean = yield select(getWebsocketConnected);
    // Verifica se o websocket está conectado
    if (websocketConnected) {
      // Pega a mensagem do payload da action
      const message: wsFormat = action.payload?.message;
      // Chama o método send do websocket com a mensagem
      yield call([websocket, websocket.send], message);
      // Dispatch da ação setLoadingChat
      yield put(setLoadingChat(true));
    } else {
      // Lança um erro se o websocket não estiver conectado
      throw new Error("Websocket not connected");
    }
  } catch (error) {
    // Trata o erro se houver algum
    console.error(error);
  }
}

// Escuta a action de enviar mensagem e chama a função correspondente
function* watchSendMessage() {
  yield takeEvery(WsActionTypes.SEND_MESSAGE, sendWebsocketMessage);
}

function* WsSaga() {
    yield all([
        fork(watchSendMessage),
    ]);
}

export default WsSaga;
