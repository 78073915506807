import { GroupData, Permission, User } from "../../helpers/types/group";
import { GroupActionTypes } from "./constants";

const INIT_STATE: State = {
  groups: [],
  currentGroup: {},
  loading: false,
};

interface GroupActionType {
  type:
    | GroupActionTypes.API_RESPONSE_SUCCESS
    | GroupActionTypes.API_RESPONSE_ERROR
    | GroupActionTypes.GET_GROUP_LIST
    | GroupActionTypes.ADD_GROUP
    | GroupActionTypes.SET_CURRENT_GROUP
    | GroupActionTypes.UPDATE_GROUP_PERMISSIONS
    | GroupActionTypes.UPDATE_GROUP_USERS
    | GroupActionTypes.SET_LOADING_GROUP
    | GroupActionTypes.UPDATE_GROUP_NAME
    | GroupActionTypes.UPDATE_GROUP_OWNER
    | GroupActionTypes.DELETE_GROUP;
  payload: {
    actionType?: string;
    groupList?: GroupData[] | [];
    currentGroup?: GroupData;
    permissions?: Permission[] | [];
    users?: User[] | [];
    error?: string;
    newName?: string;
    newOwnerId?: string;
    groupId?: string;
    isLoading?: boolean;
  };
}

interface State {
  groups: GroupData[] | [];
  currentGroup?: GroupData | {};
  loading?: boolean;
}

const Group = (state: State = INIT_STATE, action: GroupActionType): any => {
  switch (action.type) {
    case GroupActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GroupActionTypes.GET_GROUP_LIST: {
          return {
            ...state,
            groups: action.payload.groupList,
            loading: false,
          };
        }
        case GroupActionTypes.ADD_GROUP: {
          return {
            ...state,
            groups: [...state.groups, action.payload.groupList?action.payload.groupList[0]:undefined],
            loading: false,
          };
        }
        case GroupActionTypes.UPDATE_GROUP_NAME:
        case GroupActionTypes.UPDATE_GROUP_OWNER:
        case GroupActionTypes.UPDATE_GROUP_PERMISSIONS:
        case GroupActionTypes.UPDATE_GROUP_USERS: {
          const updatedGroup = action.payload.groupList?action.payload.groupList[0]:undefined;
          return {
            ...state,
            groups: state.groups.map(group =>
              group.ID === (updatedGroup && updatedGroup.ID) ? updatedGroup : group
            ),
            currentGroup:
              state.currentGroup && (state.currentGroup as GroupData).ID === updatedGroup?.ID
                ? updatedGroup
                : state.currentGroup,
            loading: false,
          };
        }
        case GroupActionTypes.DELETE_GROUP: {
          return {
            ...state,
            groups: state.groups.filter(group => group.ID !== (action.payload.groupList?action.payload.groupList[0].ID:undefined)),
            loading: false,
          };
        }
        default:
          return { ...state };
      }

    case GroupActionTypes.API_RESPONSE_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case GroupActionTypes.GET_GROUP_LIST:
    case GroupActionTypes.ADD_GROUP:
    case GroupActionTypes.UPDATE_GROUP_NAME:
    case GroupActionTypes.UPDATE_GROUP_OWNER:
    case GroupActionTypes.UPDATE_GROUP_PERMISSIONS:
    case GroupActionTypes.UPDATE_GROUP_USERS:
    case GroupActionTypes.DELETE_GROUP:
      return { ...state, loading: true };

    case GroupActionTypes.SET_CURRENT_GROUP:
      return {
        ...state,
        currentGroup: action.payload.currentGroup,
      };

    case GroupActionTypes.SET_LOADING_GROUP:
      return {
        ...state,
        loading: action.payload.isLoading,
      };

    default:
      return { ...state };
  }
};

export default Group;
