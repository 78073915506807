import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { WeebSocket } from "../helpers/api/webSocket"; // Altera o import
import { useDispatch, useSelector } from "react-redux";
import { Message } from "../helpers/types/chat";
import { addMessage, setWebsocket, websocketConnected } from "../redux/actions";

// Define o tempo de reconexão em milissegundos
const RECONNECT_INTERVAL = 5000;

// Cria um hook personalizado para gerenciar o websocket
const useWeebSocket = (url: string) => { // Adiciona os tipos aos parâmetros
    // Usa o useDispatch do React Redux para obter uma referência ao dispatch
    const dispatch = useDispatch();

    const memoizedSetWebsocket = useCallback(
      (websocket: any) => dispatch(setWebsocket(websocket)),
      [dispatch]
    );

    const memoizedwebsocketConnected = useCallback(
      (websocket: any) => dispatch(websocketConnected(websocket)),
      [dispatch]
    );

    // Cria uma versão memoizada da função addMessage
    const memoizedAddMessage = useCallback(
        (message: Message) => dispatch(addMessage(message)),
        [dispatch]
      );    

  // Usa o hook useMemo para criar a instância do WeebSocket
  const weebSocket = useMemo(() => new WeebSocket(url, memoizedAddMessage, memoizedwebsocketConnected), [url, memoizedAddMessage]); // Adiciona o tipo ao estado

  memoizedSetWebsocket(weebSocket)

  // Usa o hook useSelector para obter o estado de autenticação do usuário do redux
  const authState = useSelector((state: any) => state.Auth.userLoggedIn); // Adiciona o tipo ao estado
  const isWebsocketConnected = useSelector((state: any) => state.Weebsocket.websocketConnected);
    
  const shouldRetry = useRef<boolean>(false)
  const isRetrying = useRef<boolean>(false)

  // Cria uma função auxiliar para tentar reconectar o websocket
  const reconnect = () => {
    // Verifica se o usuário está logado e o websocket está desconectado
    if (shouldRetry.current === true) {
      isRetrying.current = true
      // Tenta se conectar ao websocket
      weebSocket.connect();
      // Agenda uma nova tentativa de reconexão após o intervalo definido
      setTimeout(reconnect, RECONNECT_INTERVAL);
    } else {
        isRetrying.current = false
    }
  };

  // Usa o hook useEffect para conectar ou desconectar o websocket de acordo com o estado de autenticação do usuário
  useEffect(() => {
    switch (authState) {
        case null: {
            // Espera carregar resultado
            shouldRetry.current = false
            break
        }
        // Usuário logado
        case true: {
            // Usuário logado e com websocket descontectado
            if (isWebsocketConnected === false) {
                shouldRetry.current = true

                // Usuário logado, com websocket desligado e sem tentar reconectar
                if (isRetrying.current === false) {
                    reconnect();
                }
            } else {
                shouldRetry.current = false
            }
            break
        }
        case false: {
            shouldRetry.current = false
            if (isWebsocketConnected === true) {
                // Desconecta o websocket
                weebSocket.disconnect();
            }
            break
        }
    }
  }, [isWebsocketConnected, authState]); // Executa o efeito quando o WeebSocket, o estado do usuário ou o token mudarem

  // Retorna a instância do WeebSocket
  return weebSocket;
};

export { useWeebSocket };
