// constants
import { AuthActionTypes } from "./constants";

export interface AuthActionType {
  type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.FORGOT_PASSWORD
    | AuthActionTypes.FORGOT_PASSWORD_CHANGE
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET
    | AuthActionTypes.SIGNUP_USER
    | AuthActionTypes.LOAD_PROFILE_PICTURE
    // | AuthActionTypes.SET_WEBSOCKET
    // | AuthActionTypes.WEBSOCKET_CONNECTED
  payload: {} | string;
}

interface UserData {
  id: number;
  email: string;
  username: string;
  user_picture: string;
  picture_url: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
}

// common success
export const authApiResponseSuccess = (
  actionType: string,
  data: UserData | {}
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const authApiResponseError = (
  actionType: string,
  error: string
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const loginUser = (email: string, password: string): AuthActionType => ({
  type: AuthActionTypes.LOGIN_USER,
  payload: { email, password },
});

export const logoutUser = (): AuthActionType => ({
  type: AuthActionTypes.LOGOUT_USER,
  payload: {},
});

export const signupUser = (
  fullname: string,
  email: string,
  password: string
): AuthActionType => ({
  type: AuthActionTypes.SIGNUP_USER,
  payload: { fullname, email, password },
});

export const forgotPassword = (email: string): AuthActionType => ({
  type: AuthActionTypes.FORGOT_PASSWORD,
  payload: { email },
});

export const loadProfilePicture = (profilePic: string): AuthActionType => ({
  type: AuthActionTypes.LOAD_PROFILE_PICTURE,
  payload: { profilePic },
});

// export const setWebsocket = (websocket: any): AuthActionType => ({
//   type: AuthActionTypes.SET_WEBSOCKET,
//   payload: { websocket }
// })

// export const websocketConnected = (status: boolean): AuthActionType => ({
//   type: AuthActionTypes.WEBSOCKET_CONNECTED,
//   payload: { status }
// })

export const resetAuth = (): AuthActionType => ({
  type: AuthActionTypes.RESET,
  payload: {},
});
