import { ChatData, Message } from "../../helpers/types/chat";
import { OptionsProps } from "./actions";
import { ChatActionTypes } from "./constants";


const INIT_STATE: State = {
    chats: [],
    currentChat: {},
    loading: true,
    chatCategories: [],
    selectedCategory: {}
  };

interface ChatActionType {
    type:
        | ChatActionTypes.API_RESPONSE_SUCCESS
        | ChatActionTypes.API_RESPONSE_ERROR
        | ChatActionTypes.GET_CHAT_LIST
        | ChatActionTypes.ADD_MESSAGE_TO_CHAT
        | ChatActionTypes.SET_CURRENT_CHAT
        | ChatActionTypes.SET_CHAT_CATEGORIES
        | ChatActionTypes.SET_SELECTED_CATEGORY
        | ChatActionTypes.SET_LOADING_CHAT;
    payload: {
        actionType?: string;
        chatList?: ChatData[] | [];
        currentChat?: ChatData;
        chatCategories?: OptionsProps[] | [];
        selectedCategory?: OptionsProps | {};
        error?: string;
        message?: Message | {};
        isLoading?: boolean;
    };
}

interface State {
    chats: ChatData[] | [];
    currentChat?: ChatData | {};
    chatCategories?: OptionsProps[] | [];
    selectedCategory?: OptionsProps | {};
    loading?: boolean;
}

const Chat = (state: State = INIT_STATE, action: ChatActionType): any => {
    switch (action.type) {
      case ChatActionTypes.API_RESPONSE_SUCCESS:
        switch (action.payload.actionType) {
          case ChatActionTypes.GET_CHAT_LIST: {
            return {
              ...state,
              chats: action.payload.chatList,
              loading: false,
            };
          }
          default:
            return { ...state };
        }
  
      case ChatActionTypes.API_RESPONSE_ERROR:
        switch (action.payload.actionType) {
          case ChatActionTypes.GET_CHAT_LIST: {
            return {
              ...state,
              error: action.payload.error,
              loading: false,
            };
          }
          default:
            return { ...state };
        }
  
      case ChatActionTypes.GET_CHAT_LIST:
        return { ...state, loading: true };

      case ChatActionTypes.ADD_MESSAGE_TO_CHAT:
        // Pega a nova mensagem do payload
        const newMessage = action.payload.message as Message;
        // Atualiza a lista de chats com a nova mensagem
        const updatedChats = state.chats?.map((chat: any) => {
          // Encontra o chat que tem o mesmo id que o chat_id da nova mensagem
          if (chat.id === newMessage.chat_id) {
            // Retira os 5 últimos valores da lista de mensagens
            const lastMessages = chat.messages.splice(-5);

            // Faz um sort dos 5 últimos valores com a nova mensagem
            const sortedMessages = [...lastMessages, newMessage].sort((a, b) => a.send_date - b.send_date);

            // Junta eles novamente ao fim da lista
            return {
              ...chat,
              messages: [...chat.messages, ...sortedMessages],
            };
          } else {
            // Retorna o chat sem alteração
            return chat;
          }
        });

        // Atualiza o chat selecionado
        const currentChatUpdater = () => {
          // @ts-ignore
          if (state.currentChat && Object.keys(state.currentChat).length !== 0 && state.currentChat.id === newMessage.chat_id) {
            // Retira os 5 primeiros valores da lista de mensagens
            // @ts-ignore
            const firstMessages = state.currentChat.messages.splice(0, 5);

            // Faz um sort dos 5 primeiros valores com a nova mensagem
            const sortedMessages = [newMessage, ...firstMessages].sort((a, b) => b.send_date - a.send_date);

            // Junta eles novamente ao início da lista
            return {
              ...state.currentChat,
              // @ts-ignore
              messages: [...sortedMessages, ...state.currentChat.messages],
            };
          } else {
            // Retorna o chat sem alteração
            return state.currentChat;
          }
        }


        const updatedCurrentChat = currentChatUpdater()

        // Retorna um novo estado com a lista de chats atualizada
        return {
          ...state,
          chats: updatedChats,
          currentChat: updatedCurrentChat,
          loading: false
        };

      case ChatActionTypes.SET_CURRENT_CHAT:
        return { ...state,
          currentChat: action.payload.currentChat
        };

      case ChatActionTypes.SET_CHAT_CATEGORIES:
        return { ...state,
          chatCategories: action.payload.chatCategories
        };

      case ChatActionTypes.SET_SELECTED_CATEGORY:
        return { ...state,
          selectedCategory: action.payload.selectedCategory
        };

      case ChatActionTypes.SET_LOADING_CHAT:
        return { ...state,
          loading: action.payload.isLoading
        };

      default:
        return { ...state };
    }
};
  
export default Chat;