import { SagaIterator } from "@redux-saga/core";
import { loadChatList as loadChatListApi, loadProfilePicture } from "../../helpers/api/chat";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { OptionsProps, chatApiResponseError, chatApiResponseSuccess, setChatCategories, setSelectedCategory } from "../actions";
import { ChatActionTypes } from "./constants";
import { ChatData } from "../../helpers/types/chat";

interface ChatParams {
    payload: {
      page: number;
    };
    type: string;
  }

/**
 * Gets chat list
 * @param {*} payload 
 */
function* loadChatList({
  }: ChatParams): SagaIterator {
    try {
        const response = yield call(loadChatListApi, {page: 1});
        const chats = response.data as ChatData[];

        // loop through the chats and load the profile pictures
        for (const chat of chats) {
          // get the picture name from the chat object
          const pictureName = chat.contact_picture;
          // call the loadProfilePicture function with the picture name
          const picture = yield call(loadProfilePicture, pictureName);
          // replace the contact_picture property with the picture
          chat.contact_picture = picture;
        }

        if (chats.length > 0) {
          const optionsList: string[] = chats
            .map((item: ChatData) => item.category_name)
            .filter(
                (value:string, index: number, current_value: string[]) => current_value.indexOf(value) === index
            );
          const options: OptionsProps[] = optionsList.map((item: string) => [{value: item, label: item}][0])
          yield put(setChatCategories(options));
          yield put(setSelectedCategory(options[0]));
        }

        yield put(chatApiResponseSuccess(ChatActionTypes.GET_CHAT_LIST, chats));
    } catch (error: any) {
        yield put(chatApiResponseError(ChatActionTypes.GET_CHAT_LIST, error));
    }
};

export function* watchLoadChatList() {
    yield takeEvery(ChatActionTypes.GET_CHAT_LIST, loadChatList);
  }

function* chatSaga() {
    yield all([
        fork(watchLoadChatList),
    ]);
}

export default chatSaga;