// Você precisa instalar esse tipo
import { IncomingMessage } from "http";
// Import padrão

import * as WsProps from "websocket";
import { Message } from "../types/chat";
import { APICore } from "./apiCore";
import { ChatActionType, WsActionType } from "../../redux/actions";

// Define a interface WebsocketContent com um tipo genérico T
interface WebsocketContent<T> {
  type: string;
  data: T;
}

const api = new APICore();

class WeebSocket {
  // Você precisa definir esses tipos
  url: string;
  socket: WebSocket | null;
  memoizedAddMessage:(message: Message) => ChatActionType;
  memoizedwebsocketConnected:(status: boolean | null) => WsActionType;

  constructor(url: string, memoizedAddMessage:(message: Message) => ChatActionType, memoizedwebsocketConnected:(status: boolean | null) => WsActionType) {
    this.url = url;
    this.socket = null;
    this.memoizedAddMessage = memoizedAddMessage;
    this.memoizedwebsocketConnected = memoizedwebsocketConnected;
  }

  connect() {
    this.memoizedwebsocketConnected(null)   // Passo nulo para definir um meio termo, ou seja, não sei ainda se vai dar certo
    const token = api.getLoggedInUser().token
    // Cria uma nova instância de WebSocket com o token no header
    this.socket = new WebSocket(this.url, ["JWT", token]); // Usa um subprotocolo para enviar o token
    this.socket.onopen = (event) => this.handleOpen(event);
    this.socket.onclose = (event) => this.handleClose(event);
    this.socket.onerror = (event) => this.handleError(event);
    this.socket.onmessage = (event) => this.handleMessage(event);
  }

  disconnect() {
    // Fecha a conexão com o servidor websocket
    if (this.socket) {
      this.socket.close();
    }
  }

  send(data: {type: string, data: any}) {
    // Envia uma mensagem para o servidor websocket
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(data));
    }
  }

  handleOpen(event: any) {
    this.memoizedwebsocketConnected(true)
  }

  handleClose(event: WsProps.ICloseEvent) {
    // Trata o fechamento da conexão
    this.memoizedwebsocketConnected(false)
    console.log("WebSocket closed:", event.code, event.reason);
  }

  handleError(error: any) {
    // Trata o erro na conexão
    console.error("WebSocket error:", error);
  }

  handleMessage(event: WsProps.IMessageEvent) {
    // Trata a mensagem recebida do servidor websocket
    // console.log("WebSocket message:", event.data);

    // Define um tipo de união que pode ser Message, Notification ou Error
    type MessageType = Message;

    if (typeof event.data === "string") {
      // Transforma event.data em um objeto javascript
      const websocketObject = JSON.parse(event.data);

      // Transforma websocketObject em um objeto do tipo WebsocketContent<MessageType>
      const websocketContent = websocketObject as WebsocketContent<MessageType>;

      // Verifica o tipo e faz o tratamento adequado
      switch (websocketContent.type) {
        case "message":
          console.log(websocketContent)
          this.memoizedAddMessage(websocketContent.data)
          break;
        default:
          // Lança um erro se o tipo não for reconhecido
          throw new Error("Invalid type");
      }
    }
  }
}

export { WeebSocket }
