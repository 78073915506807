import { Message } from "../../helpers/types/chat";
import { WsActionTypes } from "./constants";

export interface WsActionType {
    type:
      | WsActionTypes.SET_WEBSOCKET
      | WsActionTypes.WEBSOCKET_CONNECTED
      | WsActionTypes.SEND_MESSAGE
    payload?: any
}

export interface wsFormat {
    Type: "message" | "msg_update" // Fazer o update para passar mensagens lidas/alteradas
    Data: WsChatMessage
}

export interface WsChatMessage {
    Chat_id: string
    Content: string
}

export const setWebsocket = (websocket: any): WsActionType => ({
    type: WsActionTypes.SET_WEBSOCKET,
    payload: { websocket }
})
  
export const websocketConnected = (status: boolean): WsActionType => ({
    type: WsActionTypes.WEBSOCKET_CONNECTED,
    payload: { status }
})

export const sendMessage = (message: wsFormat): WsActionType => ({
    type: WsActionTypes.SEND_MESSAGE,
    payload: { message },
  });
