import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";

import "./i18n";

import App from "./App";

import { Provider, useDispatch, useSelector } from "react-redux";
import { AppDispatch, configureStore } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { useWeebSocket } from "./hooks";
import { loadProfilePicture, setWebsocket } from "./redux/actions";

const store = configureStore({})

function Root() {
  return (
    <React.Fragment>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </React.Fragment>
  );
}

// Criar um componente funcional chamado WebSocketProvider
function WebSocketProvider({ children }:{children: React.JSX.Element}) {
  // Usar o hook useWeebSocket para obter a instância do WeebSocket
  const weebSocket = useWeebSocket(process.env.REACT_APP_API_URL?.replace("https://", "wss:") + "/ws");
  // Usar o hook useDispatch para obter o dispatch do redux
  const dispatch = useDispatch<AppDispatch>();
  // Usar o hook useEffect para enviar o WeebSocket para o redux usando o dispatch
  useEffect(() => {
    dispatch(setWebsocket(weebSocket));
  }, [weebSocket, dispatch]);

  // Carrega a foto de perfil do usuário

  const user = useSelector((state:any) => state.Auth.user);

  useEffect(() => {
    if (user && user.user_picture) {
      // Dispara a ação para carregar a imagem do perfil
      dispatch(loadProfilePicture(user.user_picture));
    }
  }, [dispatch, user?.user_picture]);

  // Retornar os filhos do componente
  return children;
}

const root = ReactDOM.createRoot(document.getElementById("root")!);

// Declarar o Provider acima do Root e o WebSocketProvider envolvendo o Root
root.render(
  <Provider store={store}>
    <WebSocketProvider>
      <Root />
    </WebSocketProvider>
  </Provider>
);
