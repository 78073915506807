import { APICore } from "./apiCore";

const api = new APICore();

// History Loading
function loadHistory(params: { JWToken: string; currentPos: number; currentChat: string }) {
  const baseUrl = "/history/";
  return api.get(`${baseUrl}`, params);
}

// ChatList Loading
function loadChatList(params: { page: number }) {
  const baseUrl = "/chats/";
  return api.get(`${baseUrl}`, params);
}

// Profile Pictures Loading
async function loadProfilePicture(pictureName: string) {
  const baseUrl = "/static/chats/pictures/" + pictureName
  const res = await api.getFile(`${baseUrl}`, {});
  return URL.createObjectURL(res.data);
}

export { loadHistory, loadChatList, loadProfilePicture }