import { boolean } from "yup";
import { WsActionTypes } from "./constants";


export interface WsActionType {
    type:
        | WsActionTypes.SET_WEBSOCKET
        | WsActionTypes.WEBSOCKET_CONNECTED
        | WsActionTypes.SEND_MESSAGE
    payload?: {
        status?: boolean
        websocket?: any
        token?: string
    }
}

interface State {
    websocket: any,
    websocketConnected: boolean,
}

const INIT_STATE: State = {
    websocket: undefined,
    websocketConnected: false,
  };

const Weebsocket = (state: State = INIT_STATE, action:WsActionType):any => {
    switch (action.type) {
        case WsActionTypes.SET_WEBSOCKET:
            return {
                ...state,
                websocket: action.payload?.websocket
        }
        case WsActionTypes.WEBSOCKET_CONNECTED:
            return {
                ...state,
                websocketConnected: action.payload?.status
        }
        default:
            return { ...state };
    }
};
    
export default Weebsocket;