// constants
import { ChatData, Message } from "../../helpers/types/chat";
import { ChatActionTypes } from "./constants";

export interface ChatActionType {
  type:
    | ChatActionTypes.API_RESPONSE_SUCCESS
    | ChatActionTypes.API_RESPONSE_ERROR
    | ChatActionTypes.GET_CHAT_LIST
    | ChatActionTypes.ADD_MESSAGE_TO_CHAT
    | ChatActionTypes.SET_CURRENT_CHAT
    | ChatActionTypes.SET_CHAT_CATEGORIES
    | ChatActionTypes.SET_SELECTED_CATEGORY
    | ChatActionTypes.SET_LOADING_CHAT
  payload?: {} | string;
}

export interface OptionsProps {
  value: string;
  label: string;
}

// common success
export const chatApiResponseSuccess = (
    actionType: string,
    chatList: ChatData[] | []
  ): ChatActionType => ({
    type: ChatActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, chatList },
});
  // common error
export const chatApiResponseError = (
    actionType: string,
    error: string
  ): ChatActionType => ({
    type: ChatActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// Chat_list
export const getChatList = (): ChatActionType => ({
    type: ChatActionTypes.GET_CHAT_LIST
  });

export const addMessage = (
  message: Message
): ChatActionType => ({
  type: ChatActionTypes.ADD_MESSAGE_TO_CHAT,
  payload: { message },
});

// Set Current chat
export const setCurrentChat = (
  currentChat: ChatData | {}
): ChatActionType => ({
  type: ChatActionTypes.SET_CURRENT_CHAT,
  payload: { currentChat },
});

// Set chat type filter
export const setChatTypes = (
  currentChat: ChatData | {}
): ChatActionType => ({
  type: ChatActionTypes.SET_CURRENT_CHAT,
  payload: { currentChat },
});

export const setChatCategories = (
  chatCategories: OptionsProps[] | []
): ChatActionType => ({
  type: ChatActionTypes.SET_CHAT_CATEGORIES,
  payload: { chatCategories }
});

export const setSelectedCategory = (
  selectedCategory: OptionsProps
): ChatActionType => ({
  type: ChatActionTypes.SET_SELECTED_CATEGORY,
  payload: { selectedCategory }
})

export const setLoadingChat = (
  isLoading: boolean
): ChatActionType => ({
  type: ChatActionTypes.SET_LOADING_CHAT,
  payload: { isLoading },
});