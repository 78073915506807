import { SagaIterator } from "@redux-saga/core";
import {
  loadGroupList as loadGroupListApi,
  createGroup as createGroupApi,
  updateGroup as updateGroupApi,
  deleteGroup as deleteGroupApi
} from "../../helpers/api/groups";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  groupApiResponseError,
  groupApiResponseSuccess
} from "../actions";
import { GroupActionTypes } from "./constants";
import { GroupData } from "../../helpers/types/group";

interface GroupParams {
  payload: {
    page?: number;
    groupId?: string;
    groupData?: any;
  };
  type: string;
}

/**
 * Gets group list
 * @param {*} payload 
 */
function* loadGroupList({ payload: { page } }: GroupParams): SagaIterator {
  try {
    const response = yield call(loadGroupListApi, { page: page || 1 });
    const groups = response.data as GroupData[];

    yield put(groupApiResponseSuccess(GroupActionTypes.GET_GROUP_LIST, groups));
  } catch (error: any) {
    yield put(groupApiResponseError(GroupActionTypes.GET_GROUP_LIST, error));
  }
}

/**
 * Creates a new group
 * @param {*} payload 
 */
function* createGroup({ payload: { groupData } }: GroupParams): SagaIterator {
  try {
    const response = yield call(createGroupApi, { groupData });
    const group = response.data as GroupData;

    yield put(groupApiResponseSuccess(GroupActionTypes.ADD_GROUP, [group]));
  } catch (error: any) {
    yield put(groupApiResponseError(GroupActionTypes.ADD_GROUP, error));
  }
}

/**
 * Updates a group
 * @param {*} payload 
 */
function* updateGroup({ payload: { groupId, groupData } }: GroupParams): SagaIterator {
  try {
    const response = yield call(() => updateGroupApi({groupId:`${groupId}`, groupData}));
    const group = response.data as GroupData;

    yield put(groupApiResponseSuccess(GroupActionTypes.UPDATE_GROUP_NAME, [group]));
  } catch (error: any) {
    yield put(groupApiResponseError(GroupActionTypes.UPDATE_GROUP_NAME, error));
  }
}

/**
 * Updates group permissions
 * @param {*} payload 
 */
function* updateGroupPermissions({ payload: { groupId, groupData } }: GroupParams): SagaIterator {
  try {
    const response = yield call(() => updateGroupApi({groupId:`${groupId}`, groupData}));
    const group = response.data as GroupData;

    yield put(groupApiResponseSuccess(GroupActionTypes.UPDATE_GROUP_PERMISSIONS, [group]));
  } catch (error: any) {
    yield put(groupApiResponseError(GroupActionTypes.UPDATE_GROUP_PERMISSIONS, error));
  }
}

/**
 * Updates group users
 * @param {*} payload 
 */
function* updateGroupUsers({ payload: { groupId, groupData } }: GroupParams): SagaIterator {
  try {
    const response = yield call(() => updateGroupApi({groupId:`${groupId}`, groupData}));
    const group = response.data as GroupData;

    yield put(groupApiResponseSuccess(GroupActionTypes.UPDATE_GROUP_USERS, [group]));
  } catch (error: any) {
    yield put(groupApiResponseError(GroupActionTypes.UPDATE_GROUP_USERS, error));
  }
}

/**
 * Deletes a group
 * @param {*} payload 
 */
function* deleteGroup({ payload: { groupId } }: GroupParams): SagaIterator {
  try {
    yield call(() => deleteGroupApi({groupId:`${groupId}`}))    ;

    yield put(groupApiResponseSuccess(GroupActionTypes.DELETE_GROUP, [{ID: `${groupId}`}]));
  } catch (error: any) {
    yield put(groupApiResponseError(GroupActionTypes.DELETE_GROUP, error));
  }
}

export function* watchLoadGroupList() {
  yield takeEvery(GroupActionTypes.GET_GROUP_LIST, loadGroupList);
}

export function* watchCreateGroup() {
  yield takeEvery(GroupActionTypes.ADD_GROUP, createGroup);
}

export function* watchUpdateGroup() {
  yield takeEvery(GroupActionTypes.UPDATE_GROUP_NAME, updateGroup);
}

export function* watchUpdateGroupPermissions() {
  yield takeEvery(GroupActionTypes.UPDATE_GROUP_PERMISSIONS, updateGroupPermissions);
}

export function* watchUpdateGroupUsers() {
  yield takeEvery(GroupActionTypes.UPDATE_GROUP_USERS, updateGroupUsers);
}

export function* watchDeleteGroup() {
  yield takeEvery(GroupActionTypes.DELETE_GROUP, deleteGroup);
}

function* groupSaga() {
  yield all([
    fork(watchLoadGroupList),
    fork(watchCreateGroup),
    fork(watchUpdateGroup),
    fork(watchUpdateGroupPermissions),
    fork(watchUpdateGroupUsers),
    fork(watchDeleteGroup)
  ]);
}

export default groupSaga;
